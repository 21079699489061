import React from "react";
import { Layout, NewBikeList } from "components";
import { Hero } from "components/ACF";
import WordPressPreview from "components/WordPressPreview/WordPressPreview";
import { flatListToHierarchical } from "utils";
import { NewMotorcycleQuery } from "codegen";
import { PageProps, graphql } from "gatsby";

interface NewMotorcycleContext {
  pageNumber: number;
  humanPageNumber: number;
  skip: number;
  limit: number;
  numberOfPages: number;
  previousPagePath?: string;
  nextPagePath?: string;
}

const NewMotorcycleList = ({
  data: { page, posts, categories, wp },
}: PageProps<NewMotorcycleQuery, NewMotorcycleContext>): JSX.Element => {
  const allPosts = posts.nodes.map((node) => node);

  if (page) {
    const { acfLayout, seo, content } = page;

    return (
      <Layout pageTitle={page.title} seo={seo}>
        <>
          {acfLayout.layout &&
            Object.keys(acfLayout.layout).length > 0 &&
            acfLayout.layout?.map((item, index) => {
              if (item.fieldGroupName === "ContentNode_Acflayout_Layout_Hero") {
                return <Hero data={item} key={`hero__${index}`} />;
              }
            })}
          <NewBikeList bikes={posts.nodes} />
        </>
      </Layout>
    );
  }
};

export default WordPressPreview({ postType: "pages" })(NewMotorcycleList);

export const query = graphql`
  query NewMotorcycle($id: String!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      ...SEOPageQuery
      acfLayout {
        fieldGroupName
        layout {
          ...HeroQuery
        }
      }
    }
    posts: allWpNewMotorcycle(sort: { fields: [date], order: DESC }, limit: $postsPerPage) {
      nodes {
        ...NewMotorcyclesFields
      }
    }
    categories: allWpNewBikeCategory {
      nodes {
        name
        slug
        parentDatabaseId
        databaseId
      }
    }
  }
`;
